<template>
  <v-dialog v-model="modalView" :max-width="dekstop">
    <v-card>
      <v-toolbar dark color="utama" dense flat>
        <v-toolbar-title>Detail user</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-row>
            <!-- NIP -->
            <v-col cols="12" md="12" class="mb-n9">
              <v-text-field
                label="NIP"
                v-model="viewItem.nipBaru"
                filled
                dense
                flat
                outlined
                class="mt-2"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Nama -->
            <v-col cols="12" md="12">
              <v-text-field
                label="Nama"
                v-model="viewItem.nama"
                filled
                dense
                flat
                outlined
                class="mt-2"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <hr />
          <h3 class="pa-1">ROLE</h3>
          <hr />

          <v-row class="my-3">
            <v-col cols="12" md="6">
              <span>REFERENSI ROLE</span>
              <v-divider class="mb-3"></v-divider>

              <v-card outlined class="pa-3 mb-3">
                <v-icon left> mdi-shield-account </v-icon>
                <span class="font-weight-black">Account</span>
                <v-btn
                  icon
                  class="float-end"
                  v-if="accountV"
                  @click="accountV = false"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="float-end"
                  v-else
                  @click="accountV = !accountV"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-divider class="my-3"></v-divider>

                <div v-if="accountV">
                  <v-card
                    v-for="item in accounts"
                    :key="item.id"
                    outlined
                    class="mb-3 ml-3 pa-3"
                  >
                    <span>
                      {{ item.label }}
                    </span>
                    <div class="float-end">
                      <v-btn small icon @click="add(item)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </div>
              </v-card>

              <v-card outlined class="pa-3 mb-3">
                <v-icon left> mdi-laptop </v-icon>
                <span class="font-weight-black">SIMASN</span>
                <v-btn
                  icon
                  class="float-end"
                  v-if="simasnV"
                  @click="simasnV = false"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="float-end"
                  v-else
                  @click="simasnV = !simasnV"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-divider class="mb-3 mt-2"></v-divider>

                <div v-if="simasnV">
                  <v-card
                    v-for="item in simasns"
                    :key="item.id"
                    outlined
                    class="mb-3 ml-3 pa-3"
                  >
                    <span>
                      {{ item.label }}
                    </span>
                    <div class="float-end">
                      <v-btn small icon @click="add(item)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </div>
              </v-card>

              <v-card outlined class="pa-3 mb-3">
                <v-icon left>mdi-cogs</v-icon>
                <span class="font-weight-black">SERVICE</span>
                <v-btn
                  icon
                  class="float-end"
                  v-if="serviceV"
                  @click="serviceV = false"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="float-end"
                  v-else
                  @click="serviceV = !serviceV"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-divider class="mb-3 mt-2"></v-divider>

                <div v-if="serviceV">
                  <div class="mb-3 ml-3">
                    <h4>Kenpa</h4>
                    <v-divider class="mb-3 mt-2"></v-divider>
                    <v-card
                      v-for="item in kenpas"
                      :key="item.id"
                      outlined
                      class="mb-3 ml-3 pa-3"
                    >
                      <span>
                        {{ item.label }}
                      </span>
                      <div class="float-end">
                        <v-btn small icon @click="add(item)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </div>

                  <div class="mb-3 ml-3">
                    <h4>KGB</h4>
                    <v-divider class="mb-3 mt-2"></v-divider>
                    <v-card
                      v-for="item in kgbs"
                      :key="item.id"
                      outlined
                      class="mb-3 ml-3 pa-3"
                    >
                      <span>
                        {{ item.label }}
                      </span>
                      <div class="float-end">
                        <v-btn small icon @click="add(item)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-icon left color="utama">mdi-access-point</v-icon>
              <span>AKTIF ROLE</span>
              <v-divider class="mb-3"></v-divider>
              <div v-if="aktif == ''">
                <v-alert type="error" dense>
                  Belum Ada Role Untuk User Ini!
                </v-alert>
              </div>
              <div v-else>
                <div>
                  <v-progress-linear
                    v-if="loadingData"
                    color="utama"
                    height="6"
                    class="mb-3"
                    rounded
                    indeterminate
                  ></v-progress-linear>
                </div>

                <v-card outlined class="pa-3 mb-3">
                  <v-icon left> mdi-shield-account </v-icon>
                  <span class="font-weight-black">Account</span>
                  <v-btn
                    icon
                    class="float-end"
                    v-if="accountV"
                    @click="accountV = false"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="float-end"
                    v-else
                    @click="accountV = !accountV"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-divider class="my-3"></v-divider>

                  <div v-if="accountV">
                    <v-card
                      v-for="item in aktif.account"
                      :key="item.id"
                      outlined
                      class="mb-3 ml-3 pa-3"
                    >
                      <span>
                        {{ item.label }}
                      </span>
                      <div class="float-end">
                        <v-btn small icon @click="hapus(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                </v-card>

                <v-card outlined class="pa-3 mb-3">
                  <v-icon left> mdi-laptop </v-icon>
                  <span class="font-weight-black">SIMASN</span>
                  <v-btn
                    icon
                    class="float-end"
                    v-if="simasnV"
                    @click="simasnV = false"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="float-end"
                    v-else
                    @click="simasnV = !simasnV"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-divider class="my-3"></v-divider>

                  <div v-if="simasnV">
                    <v-card
                      v-for="item in aktif.simasn"
                      :key="item.id"
                      outlined
                      class="mb-3 ml-3 pa-3"
                    >
                      <span>
                        {{ item.label }}
                      </span>
                      <div class="float-end">
                        <v-btn small icon @click="hapus(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                </v-card>

                <v-card outlined class="pa-3 mb-3">
                  <v-icon left>mdi-cogs</v-icon>
                  <span class="font-weight-black">SERVICE</span>
                  <v-btn
                    icon
                    class="float-end"
                    v-if="serviceV"
                    @click="serviceV = false"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="float-end"
                    v-else
                    @click="serviceV = !serviceV"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-divider class="my-3"></v-divider>

                  <div v-if="serviceV">
                    <div class="mb-3 ml-3">
                      <h4>Kenpa</h4>
                      <v-divider class="mb-3 mt-2"></v-divider>
                      <v-card
                        v-for="item in aktif.service.kenpa"
                        :key="item.id"
                        outlined
                        class="mb-3 ml-3 pa-3"
                      >
                        <span>
                          {{ item.label }}
                        </span>
                        <div class="float-end">
                          <v-btn small icon @click="hapus(item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </div>

                    <div class="mb-3 ml-3">
                      <h4>KGB</h4>
                      <v-divider class="mb-3 mt-2"></v-divider>
                      <v-card
                        v-for="item in aktif.service.kgb"
                        :key="item.id"
                        outlined
                        class="mb-3 ml-3 pa-3"
                      >
                        <span>
                          {{ item.label }}
                        </span>
                        <div class="float-end">
                          <v-btn small icon @click="hapus(item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import modalView from "@/store/user/modalView";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.user;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalView() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.getData();
      this.getRef();
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    dekstop: "60%",
    session: "",
    loadingData: false,

    accountV: true,
    simasnV: false,
    serviceV: false,

    role: "",
    aktif: [],
    accounts: [],
    kenpas: [],
    simasns: [],
    kgbs: [],
  }),

  methods: {
    getData() {
      const url =
        process.env.VUE_APP_AUTH +
        "security/authority/" +
        this.viewItem.nipBaru;
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.loadingData = false;
          this.aktif = response.data.mapData.roles;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getRef() {
      const url = process.env.VUE_APP_AUTH + "security/permissions";
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.accounts = response.data.mapData.account;
          this.simasns = response.data.mapData.simasn;
          this.kenpas = response.data.mapData.service.kenpa;
          this.kgbs = response.data.mapData.service.kgb;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    async add(item) {
      this.loadingData = true;
      const data = {
        idUser: this.viewItem.idUser,
        idPermission: item.id,
      };

      const url = process.env.VUE_APP_AUTH + "security/authority";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.getData();
          } else {
            console.log("Gagal Menambah Role");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },

    async hapus(item) {
      this.loadingData = true;
      const data = {
        idUser: item.id_user_role,
        idPermission: item.id,
      };

      const url = process.env.VUE_APP_AUTH + "security/authority";
      this.http
        .delete(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          data: data,
        })
        .then((response) => {
          if (response.data.success) {
            this.getData();
          } else {
            console.log("Gagal Menambah Role");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },

    closeModal() {
      this.modalView = false;
    },
  },
};
</script>
