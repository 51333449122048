<template>
  <div>
    <v-banner class="mb-10">
      <h2>SECURITY - {{ $route.query.unor }}</h2>

      <!-- <template v-slot:actions>
        <CModalAdd />
      </template> -->
    </v-banner>

    <div class="mb-5">
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card flat>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="users"
        :loading="loading"
        :search="search"
        item-key="name"
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <CModalView />
          <CModalEdit />
          <CModalDelete />
        </template>
        <template v-slot:[`item.nomor`]="{ item }">
          {{
            users
              .map(function (x) {
                return x.idUser;
              })
              .indexOf(item.idUser) + 1
          }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon class="mr-2" small @click="viewItem(item)"> mdi-archive </v-icon>
          <!-- <v-icon class="mx-2" small @click="editItem(item)"> mdi-pencil </v-icon> -->
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>


<script>
import Cookie from "@/helper/cookie.js";

// import CModalAdd from "@/components/security/user/modalAdd";
// import CModalEdit from "@/components/security/user/modalEdit";
import CModalDelete from "@/components/security/user/modalDelete";
import CModalView from "@/components/security/user/modalView";

import modalView from "@/store/user/modalView";
import modalEdit from "@/store/user/modalEdit";
import modalHapus from "@/store/user/modalHapus";
import refreshView from "@/store/user/viewUser";

export default {
  components: {
    // CModalAdd,
    // CModalEdit,
    CModalDelete,
    CModalView,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },
  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    loading: true,
    session: "",
    search: "",

    viewIndex: "",
    editedIndex: "",
    dleteIndex: "",

    users: [],
    user: {},

    headers: [
      {
        text: "Nomor",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "NIP", align: "start", value: "nipBaru" },
      { text: "Nama", align: "start", value: "nama" },
      { text: "OPD", align: "start", value: "unor.nama" },
      {
        text: "Action",
        align: "center",
        value: "action",
        width: "105px",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url =
        process.env.VUE_APP_AUTH + "security/users/" + this.$route.params.id;
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          refreshView.commit("refreshData", false);
          this.users = response.data.mapData;
          this.loading = false;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    viewItem(item) {
      this.viewIndex = this.users.indexOf(item);
      this.user = Object.assign({}, item);
      modalView.commit("toggleModal", true);
      modalView.commit("viewModal", Object.assign({}, item));
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.user = Object.assign({}, item);
      modalEdit.commit("toggleModal", true);
      modalEdit.commit("viewModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.dleteIndex = this.users.indexOf(item);
      this.user = Object.assign({}, item);
      modalHapus.commit("toggleModal", true);
      modalHapus.commit("viewModal", Object.assign({}, item));
    },
  },
};
</script>
